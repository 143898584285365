import { useMediaQuery } from 'react-responsive'
import { useLayoutEffect } from 'react';
import Navbar from '../components/Navbar';
import Project from '../components/Project';
import EmbeddedP from '../components/EmbeddedP';

const ResearchPaper = () => {

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#f7f6f0"
    });

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1100px)' });

    return (
        <div>
            <Navbar />
            <div style={{ marginLeft: isTabletOrMobile ? "30px" : "100px" }}>
                <h1>Research</h1>
                <br />
                <EmbeddedP style={{ marginRight: isTabletOrMobile ? "9px" : "30px" }} fontSize={19}>My interests include low-latency systems, distributed systems, and compilers, among others.</EmbeddedP>
                <br></br>
                <Project title="Accelerating TinyGL Graphics Rendering with Compiler Optimizations" link="" stack={["February 2024"]} desc="Discussing methods to accelerate rendering geometry transformations in TinyGL by utilizing lightweight OpenGL function calls and GCC/LLVM compiler optimizations. " />
                <Project title="Parallel Matrix Multiplication with OpenMP" link="" stack={["December 2023"]} desc="Outlines leveraging OpenMP compiler directives to parallelize matrix multiplication operations across multiple threads and a custom partitioning algorithm for load balancing among threads." />
                <Project title="Utilizing Machine Learning in Soccer Tactics Analysis" link="" stack={["May 2023"]} desc="A paper written with guidance from Professor David Sumpter as part of the year-long AP Research program on the approach and effectiveness of using causal inference models to aid in soccer tactics analysis. " />
            </div>
        </div>
    );
};

export default ResearchPaper;
